import React from 'react';
import { Box, Text, Image, Flex, Heading } from '@chakra-ui/react';
import Container from '../components/Container';
import { IcLeaf, IcHome, IcHuman, IcDog } from '../assets';
import { headers } from '../headers';
import Header from '../components/Header';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import HomeTile from '../components/HomeTile';
import '../styles/base.css';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Home = () => {
  const breakpoints = useBreakpoint();

  const blocks = [
    {
      subjects: [
        {
          title: 'Pour qui ?',
          description:
            "• Pour tous les maîtres motivés à travailler l’éducation ou la rééducation de leur chien ou de leur chiot 🐶\n\n• Je donne également des conseils sur l’hygiène 🧽, l'entretien 🛠️ et le transport 🚚 ayant travaillé en animalerie pendant 4 ans.",
        },
        {
          title: 'Dans quel secteur ?',
          description:
            '• Dans un rayon 📍 de 35 km autour de Bruyères comprenant Épinal, Gérardmer, Rambervillers et Saint-Dié-des-Vosges 🗺️\n\n• Des séances à domicile 🏠, en ville 🏙️ ou en forêt 🌲 en fonction du besoin.\n\n• 0,25€ par km supplémentaire au dessus des 35 km de route 🚗',
        },
      ],
      image: [
        <StaticImage
          alt="Photo de groupe"
          src="../images/picture_group_1.jpg"
          width={300}
        />,
        <StaticImage
          alt="Photo de groupe"
          src="../images/picture_group_1_2.jpg"
          width={800}
        />,
      ],
    },
    {
      subjects: [
        {
          title: 'Comment ?',
          description:
            "• Des exercices adaptés au caractère, à la race et à l'âge de votre chien 🐶 mais aussi à son maître pour des résultats optimaux 🏋️\n\n• Avec la mise en place d'un suivi personnalisé tout au long des séances afin de gagner en visibilité sur la progression et l’évolution du comportement de votre chien 📈",
        },
        {
          title: 'Quels sont mes disponibilités ? Et mes tarifs ?',
          description: (
            <>
              • Je m’adapte a votre emploi du temps avec des rendez-vous en
              semaine mais aussi le week-end 📅
              <br />
              <br />• La première séance individuelle est à 50€ et les suivantes
              à 45€
              <br />
              <br />• Je propose également des séances et balades collectives,
              les tarifs sont consultables via{' '}
              <Link to="/tarifs/" style={{ fontWeight: 'bold' }}>
                ce lien
              </Link>
              .
            </>
          ),
        },
      ],
      image: null,
    },
    {
      subjects: [
        {
          title: 'Avec quelle méthode éducative ?',
          description:
            "Aucune ne met tout le monde d'accord, mais pourtant vous verrez beaucoup d'éducateurs louer l'efficacité de telle ou telle méthode d'éducation (traditionnelle, coercitive, positive ou naturelle, etc), quitte à l'appliquer aveuglement.\n\n" +
            "Personnellement, j'adapte mon accompagnement sur des critères comme le caractère, la race ou encore l'âge de votre chien. En effet, votre compagnon à quatre pattes ne réagira pas forcément à un exercice donné de la même façon que ses congénères, aussi une méthode unique pourrait se réveler inefficace.\n\n" +
            "A noter que je n'utilise pas d'accessoires tels que les colliers étrangleurs, électrostatiques ou à pointes (Torquatus), ces derniers pouvant blesser physiquement et/ou développer des troubles du comportement (agressivité, réactivité) chez l'animal.",
        },
      ],
      image: [
        <StaticImage
          alt="Photo de groupe"
          src="../images/picture_group_2.jpg"
          width={300}
        />,
        <StaticImage
          alt="Photo de groupe"
          src="../images/picture_group_2_2.jpg"
          width={800}
        />,
      ],
    },
  ];

  const subtitle =
    'Éducateur canin comportementaliste, je propose mes services dans le secteur\ndes Vosges (Épinal, Bruyères, Saint-Dié-des-Vosges, Gérardmer, etc).';

  const keyPoints = [
    {
      icon: IcLeaf,
      description: 'Approche dans le respect\net le bien-être de votre chien',
    },
    {
      icon: IcHome,
      description:
        'Apprentissage axé autour de la\ncompréhension et de la communication',
    },
    {
      icon: IcHuman,
      description:
        'Méthodes et exercices adaptés\nà chaque binôme maître/chien',
    },
    {
      icon: IcDog,
      description:
        'Ouvert à toutes les races de chien et ce quel que soit leur âge',
    },
  ];

  return (
    <Container
      fullWidth="100%"
      banner={
        <Box id="banner" position="relative" h="auto">
          <StaticImage
            src="../images/separator_with_orphee.jpg"
            alt="Photo de ma chienne Orphée"
            layout="constrained"
          />
          <Box
            position="absolute"
            style={{
              top: '50%',
              right: '45%',
              transform: 'translateY(-50%)',
            }}
          >
            <Heading
              color="white"
              fontSize={{
                base: 'medium',
                md: '2xl',
                lg: '4xl',
                xl: '5xl',
              }}
              fontWeight="400"
              whiteSpace="pre-line"
            >
              Dans le respect du chien
              <br />
              &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;et
              de son maître
            </Heading>
          </Box>
        </Box>
      }
    >
      <Box mt={8} alignSelf="center">
        <Heading
          as="h1"
          whiteSpace="pre-line"
          fontSize="2xl"
          textAlign="center"
          ms={8}
          me={8}
        >
          {subtitle}
        </Heading>
      </Box>
      <Flex
        flexDir="row"
        flexWrap="wrap"
        mt={8}
        gap={8}
        justifyContent="center"
      >
        {keyPoints.map((keyPoint, i) => (
          <Flex flexDir="column" key={i} alignItems="center">
            <Image src={keyPoint.icon} w="96px" h="96px" alt="" />
            <Heading
              whiteSpace="pre-line"
              textAlign="center"
              w={275}
              as="h2"
              fontSize="xl"
              fontWeight="700"
            >
              {keyPoint.description}
            </Heading>
          </Flex>
        ))}
      </Flex>
      <Box mt={16} w="100%">
        {blocks.map((block, i) => {
          return (
            <HomeTile subjects={block.subjects} index={i} key={i}>
              {block.image
                ? breakpoints.sm
                  ? block.image[0]
                  : block.image[1]
                : null}
            </HomeTile>
          );
        })}
      </Box>
    </Container>
  );
};

export default Home;

export function Head() {
  return <Header {...headers.home} />;
}
